import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, filter, from, switchMap, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AuthService } from '@miduca/erv-api';
import { LanguageMenuComponent } from '@miduca/ui';
import { environment } from '../environments/environment';

@Component({
  selector: 'miduca-erv-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    TranslateModule,
    MatButtonModule,
    LanguageMenuComponent,
    MatIconModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  links = [
    {
      path: 'premiums',
      label: 'ERV.PremiumList',
    },
    {
      path: 'insured',
      label: 'ERV.InsuredList',
    },
  ];

  // TODO: load from api or does every app possibly have different languages?
  languages = [
    {
      name: 'deutsch',
      iso: 'de',
    },
    {
      name: 'français',
      iso: 'fr',
    },
    {
      name: 'italiano',
      iso: 'it',
    },
  ];

  private readonly destroyed = new Subject<void>();
  isAuthorized: boolean = false;

  constructor(
    private translateService: TranslateService,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private authService: AuthService,
    private router: Router
  ) {
    this.translateService.addLangs(this.languages.map(o => o.iso));

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(
      browserLang && browserLang.match(/de|fr|it/) ? browserLang : 'de'
    );
    this.translateService.onLangChange.subscribe(lang => {
      document.documentElement.setAttribute(
        'lang',
        this.translateService.currentLang
      );
    });

    this.msalService.initialize().subscribe(() => {
      this.setLoginDisplay();
    });

    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        this.setLoginDisplay();
      });

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroyed)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  private setLoginDisplay() {
    this.msalService.instance.handleRedirectPromise().then(res => {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length === 0) {
        this.router.navigate(['/login']);
      } else {
        const profile_data = this.msalService.instance.getAllAccounts()[0];
        this.msalService.instance.setActiveAccount(profile_data);

        this.authService
          .isInGroups(environment.msalConfig.allowedGroups)
          .subscribe(isInGroup => {
            this.isAuthorized = isInGroup;
            console.log('memberOf', isInGroup);
            if (isInGroup) {
              if (this.router.url.startsWith('/login')) {
                this.router.navigate(['/']);
              }
            } else {
              this.router.navigate(['/login'], {
                queryParams: { error: 'not-allowed' },
              });
            }
          });
      }
    });
  }

  logout() {
    this.authService.microsoftLogout();
  }

  onChangeLanguage(iso: string) {
    this.translateService.use(iso);
  }
}
