<header>
  <div class="container">
    <a routerLink="/" [title]="'ERV.Home' | translate" class="logo">
      <img
        src="/assets/klubschule-logo-de.svg"
        [alt]="'ERV.Home' | translate" />
    </a>
    <nav>
      @if (isAuthorized) {
        <ul>
          @for (link of links; track link) {
            <li>
              <a
                mat-flat-button
                routerLinkActive="mat-primary"
                [routerLink]="link.path"
                [title]="link.label | translate"
                >{{ link.label | translate }}</a
              >
            </li>
          }
        </ul>
      }
    </nav>
    @if (isAuthorized) {
      <button type="button" (click)="logout()" mat-flat-button>
        <mat-icon>exit_to_app</mat-icon>
        {{ 'ERV.Logout' | translate }}
      </button>
    }
    @defer {
      <miduca-language-menu
        [languages]="languages"
        (changeLanguage)="onChangeLanguage($event)"></miduca-language-menu>
    }
  </div>
</header>
<router-outlet></router-outlet>
