export const environment = {
  translationsUrl: 'https://dev.klubschule.ch/umbraco/api/v1/Dictionaries/all/',
  ervUrl: 'https://miducaexportapiervdev.azurewebsites.net/api/Erv/',
  msalConfig: {
    clientId: '1e917188-7ac6-495d-92da-a1fcf35d9f34',
    authority:
      'https://login.microsoftonline.com/35aa8c5b-ac0a-4b15-9788-ff6dfa22901f',
    redirectUri: 'https://ervwebappdev.azurewebsites.net/',
    scopes: ['https://graph.microsoft.com/.default'],
    protectedResourceMap: new Map([
      ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
    ]),
    allowedGroups: [
      '2cbbd645-6c51-4e7a-a471-dd4386ac6d34',
      '32c171a9-a633-49d7-b234-ffd822f8fa2d',
    ],
  },
};
